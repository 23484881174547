import React from "react";
import logo from "../assets/img/logo.png";
import Navlinks from "../Components/Navlinks";
import lineImg from "../assets/img/line-img.png";
import brandLogo from "../assets/img/brand-logo.png";
import formLine from "../assets/img/form--line.png";
import ScrollToTop from "../Components/ScrollToTop";
import Description from "../Components/Description";
import ReactGA from 'react-ga4';
/* global google */

export default class Step3 extends React.Component {
    state = {
        autocomplete : "new-password"
    }

    constructor(props) {
        super(props);

        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.blurAdressField = this.blurAdressField.bind(this);
    }

    autocompleteFocus = () => {
        this.setState({
            autocomplete: "new-password"+Math.random()
        });
    }

    submitStep = () =>{
        let isError = false;

        if (!this.props.values.zip.length || !this.props.values.address.length || !this.props.values.city.length || !this.props.values.state.length) {
            this.props.setError('fullAddress');
            isError = true;            
        }

        if (!this.props.values.email.length || !(/\S+@\S+\.\S+/.test(this.props.values.email))) {
            this.props.setError('email');
            isError = true;
        }

        if (!isError) {
            this.props.next();
        }

        ScrollToTop();
    }

    componentDidMount() {
        this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current);
        this.geocoder = new google.maps.Geocoder();

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/step3'});

        ReactGA.event({
            category: 'Step 3, Address, email',
            action: 'Step 3, Address, email',
            label: 'Step 3, Address, email'
        });
    }

    blurAdressField = (e) => {
        this.props.manualHandleChange('address',"");
        this.props.manualHandleChange('city',"");
        this.props.manualHandleChange('state',"");
        this.props.manualHandleChange('zip',"");
        this.props.manualHandleChange('fullAddress',e.target.value);
        this.geocoder.geocode({ 'address': e.target.value }, function handleResults(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                const place = results[0]; 
                if (place && place.address_components && place.formatted_address) {                                   
                    this.props.manualHandleChange('fullAddress', place.formatted_address);
                    this.setState({fullAddressCorrect: true});

                    let streetNumber = false;

                    for (let i = 0; i < place.address_components.length; i++) {
                        let addressType = place.address_components[i].types[0];

                        if (addressType === 'street_number') {
                            this.props.manualHandleChange('address', place.address_components[i]['short_name']);
                            streetNumber = true;
                        }

                        if (addressType === 'route') {
                            this.props.manualHandleChange('address', this.props.values.address + " " + place.address_components[i]['short_name']);
                        }

                        if (addressType === 'locality') {
                            this.props.manualHandleChange('city', place.address_components[i]['short_name']);
                        }

                        if (addressType === 'sublocality_level_1') {
                            this.props.manualHandleChange('city', place.address_components[i]['short_name']);
                        }

                        if (addressType === 'administrative_area_level_1') {
                            this.props.manualHandleChange('state', place.address_components[i]['short_name']);
                        }

                        if (addressType === 'postal_code') {
                            this.props.manualHandleChange('zip', place.address_components[i]['short_name']);
                        }
                    }

                    if (!streetNumber) {
                        this.props.manualHandleChange('address', "");
                    }
                }
            }
        }.bind(this))
    }

    changeFullAddress = (e) => {
        this.props.manualHandleChange('address',"");
        this.props.manualHandleChange('city',"");
        this.props.manualHandleChange('state',"");
        this.props.manualHandleChange('zip',"");
        this.props.manualHandleChange('fullAddress',e.target.value);
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        this.props.manualHandleChange('fullAddress',place.formatted_address);
        this.setState({ fullAddressCorrect: true });

        let streetNumber = false;

        for (let i = 0; i < place.address_components.length; i++) {
            let addressType = place.address_components[i].types[0];

            if (addressType === 'street_number') {
                this.props.manualHandleChange('address',place.address_components[i]['short_name']);
                streetNumber = true;
            }

            if (addressType === 'route') {
                this.props.manualHandleChange('address',this.props.values.address + " " + place.address_components[i]['short_name']);
            }

            if (addressType === 'locality') {
                this.props.manualHandleChange('city',place.address_components[i]['short_name']);
            }

            if (addressType === 'sublocality_level_1') {
                this.props.manualHandleChange('city',place.address_components[i]['short_name']);
            }

            if (addressType === 'administrative_area_level_1') {
                this.props.manualHandleChange('state',place.address_components[i]['short_name']);
            }

            if (addressType === 'postal_code') {
                this.props.manualHandleChange('zip',place.address_components[i]['short_name']);
            }
        }

        if (!streetNumber) {
            this.props.manualHandleChange('address',"");
        }

    }

    render () {
        return (
            <>
            <section className="header index-banner">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <a className="navbar-brand brand" href="/">
                            <img src={logo} alt="logo-image" className="img-fluid logo"/>
                        </a>
                    </nav>
                    <span className="logo-text">Catch your lower rate today!</span>
                    <div className="row banner-content align-items-center">
                        <div className="col-lg-7 col-md-7 orderMobileLast">
                            <img src={lineImg} className="img-fluid line-img" alt=""/>
                            <ul className="our-list">
                                <li> Lower your payment</li>
                                <li>Pay off your loan faster</li>
                                <li>Get a low rate for the life of your loan</li>
                            </ul>
                            <div className="brand-logo">
                                <img src={brandLogo} className="img-fluid brand-img transparent-background" alt=""/>
                            </div>
                            <div className=" banner-links mm d-flex ">
                                <Navlinks leftSpace={true}/>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="banner-form">
                                <div className="row mb-2">
                                    <div className="col">
                                        <h1 className="form-heaading mm  text-left">Step 3</h1>
                                    </div>
                                    <div className="col text-right">
                                        <a className={"form-back-button"} onClick={this.props.back}><i className="fas fa-arrow-left"/> Back</a>
                                    </div>
                                </div>
                                <img src={formLine} className="img-fluid form-line-img" alt=""/>
                                <form>
                                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
                                    <div className="form-group">
                                        <input type="text" value={this.props.values.fullAddress} className={`border-input form-control ${!this.props.isError('fullAddress')  ? '' : 'invalid'}`} onBlur={this.blurAdressField} onFocus={this.autocompleteFocus} ref={this.autocompleteInput}  id="autocomplete" onChange={this.changeFullAddress} autoComplete={this.state.autocomplete} placeholder="Street address"/>
                                        {this.props.isError('fullAddress') &&
                                        <div className="invalid-feedback d-block">
                                            Please enter correct full address including zip code and street, city, and state.   
                                        </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={`form-control border-input ${!this.props.isError('email')  ? '' : 'invalid'}`}
                                            placeholder="Email Address"
                                            value={this.props.values.email}
                                            onChange={this.props.handleChange('email')}
                                        />
                                        {this.props.isError('email') &&
                                        <div className="invalid-feedback d-block">
                                            Please enter your email address.
                                        </div>
                                        }
                                    </div>
                                </form>
                                <div className="form-button">
                                    <a onClick={this.submitStep} className="banner-btn mb text-uppr">continue to step 4
                                        <i className="fas fa-arrow-right btn-arrow"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Description submitStep = {this.submitStep}/>
            </>
        )
    }
}
