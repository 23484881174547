import React from "react";
import logo from "../assets/img/logo.png";
import Navlinks from "../Components/Navlinks";
import lineImg from "../assets/img/line-img.png";
import brandLogo from "../assets/img/brand-logo.png";
import formLine from "../assets/img/form--line.png";
import ScrollToTop from "../Components/ScrollToTop";
import Description from "../Components/Description";
import ReactGA from 'react-ga4';

export default class Step2 extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/step2'});

        ReactGA.event({
            category: 'Step 2, Loan data',
            action: 'Step 2, Loan data',
            label: 'Step 2, Loan data'
        });
    }

    setDefault = (e) => {
        console.log(e.target.querySelector('option').innerText);
        if(e.target.querySelector('option').innerText == "Estimated Home Value"){
            if(this.props.values.estimatedHomeValue === "") {
                this.props.manualHandleChange('estimatedHomeValue', '270000');
            }
        }else if(e.target.querySelector('option').innerText == "Remaining Mortgage Balance") {
            if(this.props.values.remainingMortgageBalance === "") {
                this.props.manualHandleChange('remainingMortgageBalance', '210000');
            }
        }else if(e.target.querySelector('option').innerText == "Cash Out") {
            if(this.props.values.cashOut === "") {
                this.props.manualHandleChange('cashOut', 'Maybe');
            }
        }else if(e.target.querySelector('option').innerText == "Are You a Veteran Or Active Military?") {
            if(this.props.values.activeMilitary === "") {
                this.props.manualHandleChange('activeMilitary', 'N');
            }
        }else if(e.target.querySelector('option').innerText == "Do You Have an FHA Loan?") {
            if(this.props.values.fhaLoan === "") {
                this.props.manualHandleChange('fhaLoan', 'N');
            }
        }else if(e.target.querySelector('option').innerText == "What is your employment status?") {
            if(this.props.values.employmentStatus === "") {
                this.props.manualHandleChange('employmentStatus', 'Employed');
            }
        }
    }

    submitStep = () =>{
        let isError = false;

        if (!this.props.values.estimatedHomeValue.length) {
            this.props.setError('estimatedHomeValue');
            isError = true;
        }

        if (!this.props.values.remainingMortgageBalance.length) {
            this.props.setError('remainingMortgageBalance');
            isError = true;
        }

        if (!this.props.values.cashOut.length) {
            this.props.setError('cashOut');
            isError = true;
        }

        if (!this.props.values.activeMilitary.length) {
            this.props.setError('activeMilitary');
            isError = true;
        }

        if (!this.props.values.fhaLoan.length) {
            this.props.setError('fhaLoan');
            isError = true;
        }

        if (!this.props.values.employmentStatus.length) {
            this.props.setError('employmentStatus');
            isError = true;
        }

        if (!isError) {
            this.props.next();
        }

        ScrollToTop();
    }

    setDefault = (e) => {
        console.log(e.target.querySelector('option').innerText);
        if(e.target.querySelector('option').innerText == "Estimated Home Value"){
            if(this.props.values.estimatedHomeValue === "") {
                this.props.manualHandleChange('estimatedHomeValue', '270000');
            }
        }else if(e.target.querySelector('option').innerText == "Remaining Mortgage Balance") {
            if(this.props.values.remainingMortgageBalance === "") {
                this.props.manualHandleChange('remainingMortgageBalance', '210000');
            }
        }else if(e.target.querySelector('option').innerText == "Cash Out") {
            if(this.props.values.cashOut === "") {
                this.props.manualHandleChange('cashOut', 'Maybe');
            }
        }else if(e.target.querySelector('option').innerText == "Are You a Veteran Or Active Military?") {
            if(this.props.values.activeMilitary === "") {
                this.props.manualHandleChange('activeMilitary', 'N');
            }
        }else if(e.target.querySelector('option').innerText == "Do You Have an FHA Loan?") {
            if(this.props.values.fhaLoan === "") {
                this.props.manualHandleChange('fhaLoan', 'N');
            }
        }else if(e.target.querySelector('option').innerText == "What is your employment status?") {
            if(this.props.values.employmentStatus === "") {
                this.props.manualHandleChange('employmentStatus', 'Employed');
            }
        }
    }

    render () {
        return (
            <>
            <section className="header index-banner">
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <a className="navbar-brand brand" href="/">
                            <img src={logo} alt="logo-image" className="img-fluid logo"/>
                        </a>
                    </nav>
                    <span className="logo-text">Catch your lower rate today!</span>
                    <div className="row banner-content align-items-center">
                        <div className="col-lg-7 col-md-7 orderMobileLast">
                            <img src={lineImg} className="img-fluid line-img" alt=""/>
                            <ul className="our-list">
                                <li> Lower your payment</li>
                                <li>Pay off your loan faster</li>
                                <li>Get a low rate for the life of your loan</li>
                            </ul>
                            <div className="brand-logo">
                                <img src={brandLogo} className="img-fluid brand-img transparent-background" alt=""/>
                            </div>
                            <div className=" banner-links mm d-flex ">
                                <Navlinks leftSpace={true}/>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <div className="banner-form">
                                <div className="row mb-2">
                                    <div className="col">
                                        <h1 className="form-heaading mm  text-left">Step 2</h1>
                                    </div>
                                    <div className="col text-right">
                                        <a className={"form-back-button"} onClick={this.props.back}><i className="fas fa-arrow-left"/> Back</a>
                                    </div>
                                </div>
                                <img src={formLine} className="img-fluid form-line-img" alt=""/>
                                <form>
                                    <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
                                    <div className="form-group">
                                        <select value={this.props.values.estimatedHomeValue} className={`form-control ${!this.props.isError('estimatedHomeValue') ? '' : 'invalid'}`} onMouseDown={this.setDefault} onChange={this.props.handleChange('estimatedHomeValue')}>
                                            <option value="" disabled>Estimated Home Value</option>
                                            <option value="90000">$75,000 - $90,000</option>
                                            <option value="105000">$90,001 - $105,000</option>
                                            <option value="120000">$105,001 - $120,000</option>
                                            <option value="135000">$120,001 - $135,000</option>
                                            <option value="150000">$135,001 - $150,000</option>
                                            <option value="165000">$150,001 - $165,000</option>
                                            <option value="180000">$165,001 - $180,000</option>
                                            <option value="195000">$180,001 - $195,000</option>
                                            <option value="210000">$195,001 - $210,000</option>
                                            <option value="225000">$210,001 - $225,000</option>
                                            <option value="240000">$225,001 - $240,000</option>
                                            <option value="255000">$240,001 - $255,000</option>
                                            <option value="270000">$255,001 - $270,000</option>
                                            <option value="285000">$270,001 - $285,000</option>
                                            <option value="300000">$285,001 - $300,000</option>
                                            <option value="315000">$300,001 - $315,000</option>
                                            <option value="330000">$315,001 - $330,000</option>
                                            <option value="345000">$330,001 - $345,000</option>
                                            <option value="360000">$345,001 - $360,000</option>
                                            <option value="375000">$360,001 - $375,000</option>
                                            <option value="390000">$375,001 - $390,000</option>
                                            <option value="405000">$390,001 - $405,000</option>
                                            <option value="420000">$405,001 - $420,000</option>
                                            <option value="435000">$420,001 - $435,000</option>
                                            <option value="450000">$435,001 - $450,000</option>
                                            <option value="465000">$450,001 - $465,000</option>
                                            <option value="480000">$465,001 - $480,000</option>
                                            <option value="495000">$480,001 - $495,000</option>
                                            <option value="510000">$495,001 - $510,000</option>
                                            <option value="525000">$510,001 - $525,000</option>
                                            <option value="540000">$525,001 - $540,000</option>
                                            <option value="555000">$540,001 - $555,000</option>
                                            <option value="570000">$555,001 - $570,000</option>
                                            <option value="585000">$570,001 - $585,000</option>
                                            <option value="600000">$585,001 - $600,000</option>
                                            <option value="615000">$600,001 - $615,000</option>
                                            <option value="630000">$615,001 - $630,000</option>
                                            <option value="645000">$630,001 - $645,000</option>
                                            <option value="660000">$645,001 - $660,000</option>
                                            <option value="675000">$660,001 - $675,000</option>
                                            <option value="690000">$675,001 - $690,000</option>
                                            <option value="705000">$690,001 - $705,000</option>
                                            <option value="720000">$705,001 - $720,000</option>
                                            <option value="735000">$720,001 - $735,000</option>
                                            <option value="750000">$735,001 - $750,000</option>
                                            <option value="765000">$750,001 - $765,000</option>
                                            <option value="780000">$765,001 - $780,000</option>
                                            <option value="795000">$780,001 - $795,000</option>
                                            <option value="810000">$795,001 - $810,000</option>
                                            <option value="825000">$810,001 - $825,000</option>
                                            <option value="840000">$825,001 - $840,000</option>
                                            <option value="855000">$840,001 - $855,000</option>
                                            <option value="870000">$855,001 - $870,000</option>
                                            <option value="885000">$870,001 - $885,000</option>
                                            <option value="900000">$885,001 - $900,000</option>
                                            <option value="915000">$900,001 - $915,000</option>
                                            <option value="930000">$915,001 - $930,000</option>
                                            <option value="945000">$930,001 - $945,000</option>
                                            <option value="960000">$945,001 - $960,000</option>
                                            <option value="975000">$960,001 - $975,000</option>
                                            <option value="990000">$975,001 - $990,000</option>
                                            <option value="1000000">$990,001 - $1,000,000</option>
                                            <option value="1100000">$1.0M - $1.1M</option>
                                            <option value="1200000">$1.11M - $1.2M</option>
                                            <option value="1300000">$1.21M - $1.3M</option>
                                            <option value="1400000">$1.31M - $1.4M</option>
                                            <option value="1500000">$1.41M - $1.5M</option>
                                            <option value="1600000">$1.51M - $1.6M</option>
                                            <option value="1700000">$1.61M - $1.7M</option>
                                            <option value="1800000">$1.71M - $1.8M</option>
                                            <option value="1900000">$1.81M - $1.9M</option>
                                            <option value="2000000">$1.91M - $2.0M</option>
                                            <option value="2000001">$2.0M or more</option>
                                        </select>
                                        {this.props.isError('estimatedHomeValue') &&
                                        <div className="invalid-feedback d-block">
                                            Please select Estimated Home Value.
                                        </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <select value={this.props.values.remainingMortgageBalance} className={`form-control ${!this.props.isError('remainingMortgageBalance') ? '' : 'invalid'}`} onMouseDown={this.setDefault} onChange={this.props.handleChange('remainingMortgageBalance')}>
                                            <option value="" disabled>Remaining Mortgage Balance</option>
                                            <option value="59900">$50,000 - $59,999</option>
                                            <option value="75000">$60,000 - $79,999</option>
                                            <option value="85000">$80,000 - $89,999</option>
                                            <option value="95000">$90,000 - $99,999</option>
                                            <option value="120000">$100,000 - $120,000</option>
                                            <option value="135000">$120,001 - $135,000</option>
                                            <option value="150000">$135,001 - $150,000</option>
                                            <option value="165000">$150,001 - $165,000</option>
                                            <option value="180000">$165,001 - $180,000</option>
                                            <option value="195000">$180,001 - $195,000</option>
                                            <option value="210000">$195,001 - $210,000</option>
                                            <option value="225000">$210,001 - $225,000</option>
                                            <option value="240000">$225,001 - $240,000</option>
                                            <option value="255000">$240,001 - $255,000</option>
                                            <option value="270000">$255,001 - $270,000</option>
                                            <option value="285000">$270,001 - $285,000</option>
                                            <option value="300000">$285,001 - $300,000</option>
                                            <option value="315000">$300,001 - $315,000</option>
                                            <option value="330000">$315,001 - $330,000</option>
                                            <option value="345000">$330,001 - $345,000</option>
                                            <option value="360000">$345,001 - $360,000</option>
                                            <option value="375000">$360,001 - $375,000</option>
                                            <option value="390000">$375,001 - $390,000</option>
                                            <option value="405000">$390,001 - $405,000</option>
                                            <option value="420000">$405,001 - $420,000</option>
                                            <option value="435000">$420,001 - $435,000</option>
                                            <option value="450000">$435,001 - $450,000</option>
                                            <option value="465000">$450,001 - $465,000</option>
                                            <option value="480000">$465,001 - $480,000</option>
                                            <option value="495000">$480,001 - $495,000</option>
                                            <option value="510000">$495,001 - $510,000</option>
                                            <option value="525000">$510,001 - $525,000</option>
                                            <option value="540000">$525,001 - $540,000</option>
                                            <option value="555000">$540,001 - $555,000</option>
                                            <option value="570000">$555,001 - $570,000</option>
                                            <option value="585000">$570,001 - $585,000</option>
                                            <option value="600000">$585,001 - $600,000</option>
                                            <option value="615000">$600,001 - $615,000</option>
                                            <option value="630000">$615,001 - $630,000</option>
                                            <option value="645000">$630,001 - $645,000</option>
                                            <option value="660000">$645,001 - $660,000</option>
                                            <option value="675000">$660,001 - $675,000</option>
                                            <option value="690000">$675,001 - $690,000</option>
                                            <option value="705000">$690,001 - $705,000</option>
                                            <option value="720000">$705,001 - $720,000</option>
                                            <option value="735000">$720,001 - $735,000</option>
                                            <option value="750000">$735,001 - $750,000</option>
                                            <option value="765000">$750,001 - $765,000</option>
                                            <option value="780000">$765,001 - $780,000</option>
                                            <option value="795000">$780,001 - $795,000</option>
                                            <option value="810000">$795,001 - $810,000</option>
                                            <option value="825000">$810,001 - $825,000</option>
                                            <option value="840000">$825,001 - $840,000</option>
                                            <option value="855000">$840,001 - $855,000</option>
                                            <option value="870000">$855,001 - $870,000</option>
                                            <option value="885000">$870,001 - $885,000</option>
                                            <option value="900000">$885,001 - $900,000</option>
                                            <option value="915000">$900,001 - $915,000</option>
                                            <option value="930000">$915,001 - $930,000</option>
                                            <option value="945000">$930,001 - $945,000</option>
                                            <option value="960000">$945,001 - $960,000</option>
                                            <option value="975000">$960,001 - $975,000</option>
                                            <option value="990000">$975,001 - $990,000</option>
                                            <option value="1000000">$990,001 - $1,000,000</option>
                                            <option value="1100000">$1.0M - $1.1M</option>
                                            <option value="1200000">$1.11M - $1.2M</option>
                                            <option value="1300000">$1.21M - $1.3M</option>
                                            <option value="1400000">$1.31M - $1.4M</option>
                                            <option value="1500000">$1.41M - $1.5M</option>
                                            <option value="1600000">$1.51M - $1.6M</option>
                                            <option value="1700000">$1.61M - $1.7M</option>
                                            <option value="1800000">$1.71M - $1.8M</option>
                                            <option value="1900000">$1.81M - $1.9M</option>
                                            <option value="2000000">$1.91M - $2.0M</option>
                                            <option value="2000001">$2.0M or more</option>
                                        </select>
                                        {this.props.isError('remainingMortgageBalance') &&
                                        <div className="invalid-feedback d-block">
                                            Please select Remaining Mortgage Balance.
                                        </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <select value={this.props.values.cashOut} className={`form-control ${!this.props.isError('cashOut') ? '' : 'invalid'}`} onMouseDown={this.setDefault} onChange={this.props.handleChange('cashOut')}>
                                            <option value="" disabled>Cash Out</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                            <option value="Maybe">Maybe</option>
                                        </select>
                                        {this.props.isError('cashOut') &&
                                        <div className="invalid-feedback d-block">
                                            Please select an option.
                                        </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <select value={this.props.values.activeMilitary} className={`form-control ${!this.props.isError('activeMilitary') ? '' : 'invalid'}`} onMouseDown={this.setDefault} onChange={this.props.handleChange('activeMilitary')}>
                                            <option value="" disabled>Are You a Veteran Or Active Military?</option>
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                        </select>
                                        {this.props.isError('activeMilitary') &&
                                        <div className="invalid-feedback d-block">
                                            Please select an option.
                                        </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <select value={this.props.values.fhaLoan} className={`form-control ${!this.props.isError('fhaLoan') ? '' : 'invalid'}`} onMouseDown={this.setDefault} onChange={this.props.handleChange('fhaLoan')}>
                                            <option value="" disabled>Do You Have an FHA Loan?</option>
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                            <option value="NS">Not sure</option>
                                        </select>
                                        {this.props.isError('fhaLoan') &&
                                        <div className="invalid-feedback d-block">
                                            Please select an option.
                                        </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <select value={this.props.values.employmentStatus} className={`form-control ${!this.props.isError('employmentStatus') ? '' : 'invalid'}`} onMouseDown={this.setDefault} onChange={this.props.handleChange('employmentStatus')}>
                                            <option value="" disabled>What is your employment status?</option>
                                            <option value="Employed">Employed</option>
                                            <option value="Self-Employed">Self-Employed</option>
                                            <option value="Retired">Retired</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        {this.props.isError('employmentStatus') &&
                                        <div className="invalid-feedback d-block">
                                            Please select your employment status.
                                        </div>
                                        }
                                    </div>
                                </form>
                                <div className="form-button">
                                    <a onClick={this.submitStep} className="banner-btn mb text-uppr">continue to step 3
                                        <i className="fas fa-arrow-right btn-arrow"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Description submitStep = {this.submitStep}/>
            </>
        )
    }
}
