import React from "react"
import Footer from "./Components/Footer";
import Description from "./Components/Description";
import Step1 from "./Formsteps/Step1";
import Step2 from "./Formsteps/Step2";
import Step3 from "./Formsteps/Step3";
import Step4 from "./Formsteps/Step4";
import Final from "./Formsteps/Final";
import axios from "axios";
import queryString from 'query-string'

export default class App extends React.Component {
    state = {
        step: 1,
        signupSuccess: false,
        fullAddress : "",
        address : "",
        city : "",
        state : "",
        zip : "",
        apt : "",
        homeType : "",
        creditScore : "",
        estimatedHomeValue : "",
        remainingMortgageBalance : "",
        cashOut : "",
        activeMilitary : "",
        fhaLoan : "",
        email : "",
        firstName : "",
        lastName : "",
        phone : "",
        ipaddress : "",
        publisher_id : "",
        upload_type : "RC",
        subid : "",
        cake_id : "",
        rcid : "",
        hid : "",
        type : "REFINANCE",
        employmentStatus : "",
        incorrectFields:[]
    }

    componentDidMount() {
        const getParameters = queryString.parse(window.location.search);
        let publisher_id,rcid,subid,cake_id;

        if (getParameters.publisher_id) {
            publisher_id = getParameters.publisher_id
        }else {
            publisher_id = 632;
        }

        if (getParameters.rcid) {
            rcid = getParameters.rcid
        }else {
            rcid = "";
        }

        if (getParameters.subid) {
            subid = getParameters.subid
        }else {
            subid = "";
        }

        if (getParameters.cake_id) {
            cake_id = getParameters.cake_id
        }else {
            cake_id = "";
        }

        this.manualHandleChange('publisher_id',publisher_id);
        this.manualHandleChange('rcid',rcid);
        this.manualHandleChange('subid',subid);
        this.manualHandleChange('cake_id',cake_id);

        axios.get(process.env.REACT_APP_API_URL+'/api/user/ip_address/').then(res => {
                this.manualHandleChange('ipaddress',res.data.ip_address);
            });

        axios.get(process.env.REACT_APP_API_URL+'/api/hdi/',{
            params: {
                upload_type: this.state.upload_type,
                publisher_id: publisher_id
            }
        }).then(res => {
                this.manualHandleChange('hid',res.data.hid);
            });
    }

    manualHandleChange = (name,val) => {
        this.setState({
            [name]: val
        });
        this.removeError(name);
    }

    next = () => {
        this.setState(prevState => {
            return {step: prevState.step + 1
        }});
    }

    back = () => {
        this.setState(prevState => {
            return {step: prevState.step - 1
            }});
    }

    openMainPage = () => {
        this.setState({step:1});
        window.scrollTo(0, 0);
    }

    handleChange = input => e => {
        this.setState({
            [input]: e.target.value
        })
        this.removeError(input);
    }

    handleSubmit = () =>{
        this.setState({
            signupSuccess: true
        }, () => this.next());
    }

    setError = (name) => {
        if (!this.isError(name)) {
            let errorList = this.state.incorrectFields;
            errorList.push(name)
            this.setState({
                incorrectFields: errorList
            });
        }
    }

    removeError = (name) => {
        if (this.isError(name)) {
            let errorList = this.state.incorrectFields;
            errorList = errorList.filter(item => item !== name);
            this.setState({
                incorrectFields: errorList
            });
        }
    }

    isError = (name) => {
        return (this.state.incorrectFields.indexOf(name) >= 0);
    }

    renderStep(step) {
        const values = this.state;
        
        switch (step) {
            case 1:
                return (
                    <Step1
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.openMainPage}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}
                        openMainPage = {this.openMainPage}
                    />
                )
            case 2:
                return (
                    <Step2
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}
                        openMainPage = {this.openMainPage}
                    />
                )
            case 3:
                return (
                    <Step3
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}
                        openMainPage = {this.openMainPage}
                    />
                )
            case 4:
                return (
                    <Step4
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        back = {this.back}
                        manualHandleChange = {this.manualHandleChange}
                        setError = {this.setError}
                        removeError = {this.removeError}
                        isError = {this.isError}
                        openMainPage = {this.openMainPage}
                    />
                )
            case 5:
                return (
                    <Final
                        values = {values}
                        handleChange = {this.handleChange}
                        next = {this.next}
                        handleSubmit = {this.handleSubmit}
                        openMainPage = {this.openMainPage}
                    />
                )
        }
    }

    render() {
        const { step } = this.state

        return (
            <div>
                {this.renderStep(step)}
                <Footer/>
            </div>
        );
    }
}
