import React from "react";
import logo from "../assets/img/logo.png";
import Navlinks from "../Components/Navlinks";
import brandLogo from "../assets/img/brand-logo.png";
import axios from "axios";
import ReactGA from 'react-ga4';

export default class Final extends React.Component {
    state = {
        isSending : true
    }

    constructor(props) {
        super(props);       
    }

    componentDidMount() {        
        const script = document.createElement("script");
        script.src = "https://cdn.myadoptimizer.com/maojsfiles/landingpagesjs/2f739c29-e650-4202-877a-f9624cb5bd9f.js";
        script.async = true;
        document.head.appendChild(script);    
        var anura_json = sessionStorage.getItem('anura_final_response');

        const postData = {
            publisher_id : this.props.values.publisher_id,
            upload_type : this.props.values.upload_type,
            server_code : this.props.values.upload_type,
            subid : this.props.values.subid,
            cake_id : this.props.values.cake_id,
            rcid : this.props.values.rcid,
            hid : this.props.values.hid,
            type : this.props.values.type,
            field_31 : window.leadId,
            universal_leadid : window.leadId,
            ipaddress : this.props.values.ipaddress,
            response : "json",
            field_3 : this.props.values.remainingMortgageBalance,
            field_9 : 'Refinance',
            field_8 : "4.35",
            email_address : this.props.values.email,
            talon : document.getElementById('talon').value,
            anura: anura_json,
            field_32 : "https://cert.trustedform.com/"+window.trustedFormId,
            useragent : navigator.userAgent,
            field_1 : this.props.values.homeType,
            field_6 : this.props.values.creditScore,
            field_2: this.props.values.estimatedHomeValue,
            field_4 : this.props.values.remainingMortgageBalance,
            cash_out : this.props.values.cashOut==='No'?'No':'Yes',
            field_28 : this.props.values.activeMilitary,
            field_23 : this.props.values.fhaLoan==='Y'?'Y':'N',
            street_address : this.props.values.address,
            zip : this.props.values.zip,
            city : this.props.values.city,
            state : this.props.values.state,
            first_name: this.props.values.firstName,
            last_name: this.props.values.lastName,
            home_phone: this.props.values.phone,
            field_34 : this.props.values.employmentStatus,
            field_18 : 50000,
            field_19 : 2000
        }

        const formData = new FormData();
        Object.keys(postData).forEach((key) => {
            formData.append(key, postData[key])
        })

        axios.post(process.env.REACT_APP_API_URL+'/formdov.php',formData).finally((res) => {
            axios.post('https://api.convertkit.com/v3/forms/993490/subscribe',{
                "api_key": "CMdhSOW0gEh_Ncw1mxAK8g",
                email: this.props.values.email,
                first_name: this.props.values.firstName,
                email_address: this.props.values.email,
                fields: {
                    first_name: this.props.values.firstName,
                    last_name: this.props.values.lastName,
                    address: this.props.values.address,
                    city: this.props.values.city,
                    state: this.props.values.state,
                    homeowner: 'Y',
                    zip: this.props.values.zip
                }
            });
        });

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: 'pageview', page: '/clickwall'});

        ReactGA.event({
            category: 'RC Conversion',
            action: 'RC Conversion',
            label: 'RC Conversion'
        });
    }

    render () {   
        
        return (
            <section className="header-new form-header">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-lg-1"/>
                        <div className="col-lg-10">
                            <a className="navbar-brand brand" href="/">
                                <img src={logo} alt="logo-image" className="img-fluid logo form-logo"/>
                            </a>
                            <div className=" form-box multistep-form ">
                                <div className="tab2">
                                    <h1 className="congrats-heading2 mb multi-form-steps text-center"> DONE
                                    </h1>
                                    <div className="best-content2 mm">
                                        Congratulations you have been matched! Click on a lender below!
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div id="target" className="text-left"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="brand-logo">
                                <img src={brandLogo} className="img-fluid brand-img transparent-background" alt=""/>
                            </div>
                            <div className=" banner-links mm d-flex justify-content-center">
                                <Navlinks/>
                            </div>
                        </div>
                        <div className="col-lg-1"/>
                    </div>
                </div>
            </section>
        )
    }
}
